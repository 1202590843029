import { addAttributeApi } from '@entities/attributes'
import { API_ATTRIBUTE_TYPE, API_QUERY_KEY, queryClient } from '@shared/api'
import { MESSAGE } from '@shared/config'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'

import { type AddAttributeFormInputs, type UseAddAttributeArgs } from './types'

const useAddAttribute = ({ onClose }: UseAddAttributeArgs) => {
  const methods = useForm<AddAttributeFormInputs>({
    defaultValues: { type: String(API_ATTRIBUTE_TYPE.TEXT) },
  })

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  const mutation = useMutation({
    mutationFn: async ({ name, type, group }: AddAttributeFormInputs) => {
      const newAttribute = await addAttributeApi({
        name: name,
        attribute_type: Number(type),
        object_group: group ? Number(group) : undefined,
        //TODO: slug убрать потом, это затычка, т.к. на бэке не генерируется
        slug: 'asdf123hlkjhsdafu4',
      })

      return newAttribute
    },
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: MESSAGE.SERVER_ERROR,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY.ATTRIBUTE_SKU_LIST] })
      onClose()
    },
  })

  const addAttribute = methods.handleSubmit((fieldsData) => {
    mutation.mutate(fieldsData)
  })

  return {
    handleModalClose,
    methods,
    addAttribute,
    isLoading: mutation.isPending,
  }
}

export { useAddAttribute }
