import {
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderLink,
  EuiIcon,
  EuiPopover,
} from '@elastic/eui'
import { useAttributeList } from '@entities/attributes'
import { EMPTY_ARRAY } from '@shared/config'
import { useToggle } from '@shared/hooks'

import { useAddAttributeToGroup } from '../model'
import { list } from './styles'

export const AddAttributeToGroup: ReactFc = () => {
  const [isMenuOpen, toggleMenu] = useToggle()
  const { isLoading, data: attributes = EMPTY_ARRAY } = useAttributeList()

  const { isPending, addToGroup, isProcessed } = useAddAttributeToGroup()

  const attributesList = attributes
    .filter((attribute) => {
      return !attribute.object_group
    })
    .map((attribute) => {
      return (
        <EuiContextMenuItem
          key={attribute.id}
          icon="plus"
          onClick={addToGroup(attribute.id)}
          disabled={isProcessed(attribute.id)}
        >
          {attribute.name}
        </EuiContextMenuItem>
      )
    })

  const isDisabled = !attributesList.length

  const addToGroupButton = (
    <EuiHeaderLink
      iconType="arrowDown"
      iconSide="right"
      color="primary"
      onClick={toggleMenu}
      isLoading={isLoading || isPending}
      isDisabled={isDisabled}
    >
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem>Добавить атрибуты</EuiFlexItem>
        <EuiFlexItem>
          <EuiIcon type="plusInCircle" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiHeaderLink>
  )

  return (
    <EuiPopover
      id="addToGroupPopover"
      button={addToGroupButton}
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
      panelPaddingSize="none"
      anchorPosition="downCenter"
    >
      <EuiContextMenuPanel items={attributesList} css={list} />
    </EuiPopover>
  )
}
