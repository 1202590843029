import { useAttributeGroupsList } from '@entities/attributes'

// TODO: опции потом должны будут приходить с бэка
const useAttributeGroupOptions = () => {
  const { data: groupData, isLoading } = useAttributeGroupsList()

  const groupOptions = groupData.map(({ id, name }) => {
    return {
      value: String(id),
      inputDisplay: name,
    }
  })

  return { groupOptions, isLoading }
}

export { useAttributeGroupOptions }
