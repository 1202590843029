import { type CategoryFilterTree, type CategoryFilterTreeProps } from '../types'
import { CategoryFilterCheckbox } from './category-filter-checkbox'
import { CategoryFilterItem } from './category-filter-item'

export const getCategoryFilterTree = ({
  items,
  enableCheckbox = false,
}: CategoryFilterTreeProps): CategoryFilterTree[] => {
  return items.map((item) => {
    const { id, name, skus_count, childNodes, isExpanded, children } = item

    return {
      id: `category_${id}`,
      label: enableCheckbox ? (
        <CategoryFilterCheckbox
          value={id}
          label={name}
          skus_count={skus_count}
          childNodes={childNodes}
        />
      ) : (
        <CategoryFilterItem name={name} skus_count={skus_count} />
      ),
      isExpanded: isExpanded,
      children: children && getCategoryFilterTree({ items: children, enableCheckbox }),
    }
  })
}
