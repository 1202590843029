import { EuiPageSection } from '@elastic/eui'
import { ATTRIBUTES_COLUMNS, useAttributeList } from '@entities/attributes'
import { type ApiAttribute } from '@shared/api/types'
import { EMPTY_ARRAY } from '@shared/config'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'

export const AttributesTable: ReactFc = () => {
  const { sorting, sortList, onTableChange } = useSortTable<ApiAttribute>()
  const { selection } = useSelectionTable<ApiAttribute>()
  const { isLoading, data = EMPTY_ARRAY } = useAttributeList()

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={ATTRIBUTES_COLUMNS}
        items={sortList(data)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}
