import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = () => {
  return {
    actions: css`
      padding-right: 10px;
    `,
  }
}
