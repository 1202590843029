import {
  API_ATTRIBUTE_LIST,
  API_QUERY_KEY,
  buildRequestApi,
  HTTP_METHOD,
  queryClient,
} from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const useAddAttributeToGroup = () => {
  const { id: objectGroupId } = useParams()

  const mutation = useMutation({
    mutationFn: (attributeId: number) => {
      const addObjectGroup = buildRequestApi<ApiAttribute>(
        `${API_ATTRIBUTE_LIST}${attributeId}/`,
        'Ошибка добавления атрибута в группу',
        {
          method: HTTP_METHOD.PATCH,
          body: {
            object_group: Number(objectGroupId),
          },
        },
      )

      return addObjectGroup()
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY.ATTRIBUTE_SKU_LIST] })
      mutation.reset()
    },
  })

  const addToGroup = (id: number) => {
    return () => {
      mutation.mutate(id)
    }
  }

  const isProcessed = (id: number) => {
    return mutation.variables === id
  }

  const isPending = mutation.isPending

  return {
    isPending,
    addToGroup,
    isProcessed,
  }
}
