import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiTab,
  EuiTabs,
  EuiText,
} from '@elastic/eui'
import { useAttributeGroupsList } from '@entities/attributes'
import { useSkuApi } from '@entities/catalog'
import { Accordion } from '@shared/ui/accordion'
import { minHFull } from '@shared/ui/styles'
import { Fragment, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

const Attributes: ReactFc = () => {
  const { id } = useParams()
  const sku = useSkuApi(id)
  const attributesGroups = useAttributeGroupsList()

  const isLoading = sku.isLoading || attributesGroups.isLoading

  if (isLoading) {
    return null
  }

  return (
    <>
      {attributesGroups.data?.map((group, index) => {
        return (
          <Fragment key={group.id}>
            <Accordion
              id={String(group.id)}
              buttonContent={group.name}
              paddingSize="l"
              initialIsOpen={index == 0 && !isLoading}
              isLoading={isLoading}
            >
              <EuiFlexGroup direction="column" gutterSize="s">
                {sku.data?.attributes
                  .filter((atrtribute) => {
                    return atrtribute.object_group === group.id
                  })
                  .map((atrtribute) => {
                    return (
                      <EuiFlexGroup
                        key={atrtribute.id}
                        alignItems="center"
                        justifyContent="spaceBetween"
                      >
                        <EuiFlexItem grow={1}>{atrtribute.name}</EuiFlexItem>
                        <EuiFlexItem grow={3}>
                          <EuiFieldText defaultValue={atrtribute.value} fullWidth compressed />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    )
                  })}
              </EuiFlexGroup>
            </Accordion>
            <EuiHorizontalRule margin="s" />
          </Fragment>
        )
      })}
    </>
  )
}

// TODO: Раскидать по FSD
const tabs = [
  {
    id: 'attributes',
    name: 'Атрибуты',
    content: <Attributes />,
  },
  {
    id: 'imagesAndFiles',
    name: 'Изображения и файлы',
    content: (
      <EuiText>
        <p>Контент Изображения и файлы</p>
      </EuiText>
    ),
  },
  {
    id: 'taxonomy',
    name: 'Таксономия',
    content: (
      <EuiText>
        <p>Контент Таксономия</p>
      </EuiText>
    ),
  },
  {
    id: 'categories',
    name: 'Категории',
    content: (
      <EuiText>
        <p>Контент Категории</p>
      </EuiText>
    ),
  },
  {
    id: 'certificates',
    name: 'Сертификаты',
    content: (
      <EuiText>
        <p>Контент Сертификаты</p>
      </EuiText>
    ),
  },
  {
    id: 'relatedProducts',
    name: 'Связанные товары',
    content: (
      <EuiText>
        <p>Контент Связанные товары</p>
      </EuiText>
    ),
  },
  {
    id: 'bundles',
    name: 'Комплекты',
    content: (
      <EuiText>
        <p>Контент Комплекты</p>
      </EuiText>
    ),
  },
]

export const SkuTabs: ReactFc = () => {
  const [selectedTabId, setSelectedTabId] = useState('attributes')

  const selectedTabContent = useMemo(() => {
    return tabs.find((obj) => {
      return obj.id === selectedTabId
    })?.content
  }, [selectedTabId])

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id)
  }

  const renderTabs = () => {
    return tabs.map((tab, index) => {
      return (
        <EuiTab
          key={index}
          onClick={() => {
            return onSelectedTabChanged(tab.id)
          }}
          isSelected={tab.id === selectedTabId}
        >
          {tab.name}
        </EuiTab>
      )
    })
  }

  return (
    <EuiPanel paddingSize="none">
      <EuiFlexGroup direction="column" gutterSize="none" css={minHFull}>
        <EuiFlexItem grow={false}>
          <EuiPanel hasShadow={false}>
            <EuiTabs>{renderTabs()}</EuiTabs>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel color="subdued">{selectedTabContent}</EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
