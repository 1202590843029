import { type EuiBasicTableColumn } from '@elastic/eui'
import { API_ATTRIBUTE_MODEL, API_QUERY_KEY, useAttributeListApi } from '@shared/api'
import { EMPTY_ARRAY } from '@shared/config'
import { isNotNull } from '@shared/lib'

import { renderImage, renderProgress } from '../lib'
import { type Sku } from '../types'

export const useCatalogColumns = (): Array<EuiBasicTableColumn<Sku>> => {
  const { isSuccess, data: skuAttributes } = useAttributeListApi(API_ATTRIBUTE_MODEL.SKU, [
    API_QUERY_KEY.ATTRIBUTE_SKU_LIST,
  ])

  if (!isSuccess) {
    return EMPTY_ARRAY
  }

  const progressColumn = {
    field: 'progress',
    name: 'Прогресс',
    width: '150px',
    render: renderProgress,
  }

  let previewIndex = -1

  const columns = skuAttributes
    .sort((firstAttribute, secondAttribute) => {
      // Сортируем по is_preview, is_name, is_sku и затем по id
      if (firstAttribute.is_preview !== secondAttribute.is_preview) {
        return firstAttribute.is_preview ? -1 : 1
      }

      if (firstAttribute.is_name !== secondAttribute.is_name) {
        return firstAttribute.is_name ? -1 : 1
      }

      if (firstAttribute.is_sku !== secondAttribute.is_sku) {
        return firstAttribute.is_sku ? -1 : 1
      }

      return firstAttribute.id - secondAttribute.id
    })
    .map((attribute, index) => {
      const column: EuiBasicTableColumn<Sku> = {
        field: attribute.slug,
        name: attribute.name,
      }

      // Статус публикации не выводится в колонке. TODO: удалится и будет как прогресс
      if (attribute.id === 6) {
        return null
      }

      // Колонка с превью
      if (attribute.is_preview) {
        column.width = '100px'
        column.render = renderImage
        column.sortable = false

        previewIndex = index
      }

      return column
    })
    .filter(isNotNull)

  columns.splice(previewIndex + 1, 0, progressColumn)

  return columns
}
