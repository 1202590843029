import { EuiPageSection } from '@elastic/eui'
import { ATTRIBUTE_GROUP_COLUMNS, useAttributeList } from '@entities/attributes'
import { type ApiAttribute } from '@shared/api/types'
import { EMPTY_ARRAY } from '@shared/config'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'
import { useParams } from 'react-router-dom'

export const AttributeGroupTable: ReactFc = () => {
  const { id } = useParams()
  const { sorting, sortList, onTableChange } = useSortTable<ApiAttribute>()
  const { selection } = useSelectionTable<ApiAttribute>()
  const { isLoading, data = EMPTY_ARRAY } = useAttributeList()

  const filteredList = data.filter((attribute) => {
    return attribute.object_group === Number(id)
  })

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={ATTRIBUTE_GROUP_COLUMNS}
        items={sortList(filteredList)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}
