import { EuiFlexGroup, EuiFlexItem, EuiHeader } from '@elastic/eui'
import { noShadow, useStyles } from '@shared/ui/styles'
import { AttributeActions } from '@widgets/settings'

import { getStyles } from './styles'

export const AttributesHeader: ReactFc = () => {
  const styles = useStyles(getStyles)

  return (
    <EuiHeader css={noShadow}>
      <EuiFlexGroup alignItems="center" justifyContent="flexEnd">
        <EuiFlexItem css={styles.actions} grow={false}>
          <AttributeActions />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiHeader>
  )
}
