import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiListGroup, EuiListGroupItem } from '@elastic/eui'
import { AttributeFormField } from '@entities/attributes'
import { MESSAGE } from '@shared/config'
import { FormError } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { Progress } from '@shared/ui/progress'
import { textRight } from '@shared/ui/styles'
import { FormProvider } from 'react-hook-form'

import { useEditAttribute } from '../model'
import { list } from './styles'
import { type SkuAttributesListProps } from './types'

export const SkuAttributesList: ReactFc<SkuAttributesListProps> = ({ attributes }) => {
  return (
    <EuiListGroup flush gutterSize="none" css={list}>
      {attributes.map((attribute) => {
        if (attribute.slug === 'progress') {
          return (
            <EuiListGroupItem
              key={attribute.id}
              label={
                <EuiFlexGroup gutterSize="s" justifyContent="spaceBetween">
                  <EuiFlexItem>{attribute.name}</EuiFlexItem>
                  <EuiFlexItem css={textRight}>
                    <Progress value={Number(attribute.value)} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          )
        }

        const { isOpen, showModal, closeModal, methods, saveAttribute, isLoading } =
          useEditAttribute({
            defaultValues: {
              attributeId: attribute.id,
              attributeValueId: attribute.value_id,
              value: attribute.value,
            },
          })

        return (
          <EuiListGroupItem
            key={attribute.id}
            label={
              <EuiFlexGroup gutterSize="s" justifyContent="spaceBetween">
                <EuiFlexItem>{attribute.name}</EuiFlexItem>
                <EuiFlexItem css={textRight}>{attribute.value || '–'}</EuiFlexItem>
                <FormProvider {...methods}>
                  <Modal
                    title="Редактировать атрибут"
                    onClose={closeModal}
                    submitButtonText={MESSAGE.SAVE}
                    onSubmit={saveAttribute}
                    isOpen={isOpen}
                    isLoading={isLoading}
                  >
                    <EuiForm component="form" onSubmit={saveAttribute}>
                      <AttributeFormField {...attribute} isLoading={isLoading} />

                      <FormError />
                    </EuiForm>
                  </Modal>
                </FormProvider>
              </EuiFlexGroup>
            }
            extraAction={{
              color: 'primary',
              iconType: 'pencil',
              alwaysShow: true,
              'aria-label': MESSAGE.EDIT,
              onClick: showModal,
            }}
            size="s"
            wrapText
          />
        )
      })}
    </EuiListGroup>
  )
}
