import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { MESSAGE } from '@shared/config'
import { NumberInput, TextInput } from '@shared/ui/form'

import { type AttributeFormFieldProps } from './types'

const AttributeFormField: ReactFc<AttributeFormFieldProps> = ({
  isLoading,
  attribute_type: attributeType,
  name,
  value,
}) => {
  switch (attributeType) {
    case API_ATTRIBUTE_TYPE.TEXT:
      return (
        <TextInput
          name="value"
          placeholder={name}
          registerOptions={{ required: MESSAGE.ATTRIBUTE_EMPTY_ERROR }}
          inputProps={{
            defaultValue: value,
            isLoading: isLoading,
            required: true,
          }}
        />
      )
    case API_ATTRIBUTE_TYPE.NUMBER:
      return (
        <NumberInput
          name="value"
          placeholder={name}
          registerOptions={{ required: MESSAGE.ATTRIBUTE_EMPTY_ERROR }}
          inputProps={{
            defaultValue: value,
            isLoading: isLoading,
            required: true,
          }}
        />
      )
    case API_ATTRIBUTE_TYPE.LIST:
      return null
    case API_ATTRIBUTE_TYPE.BOOL:
      return null
    case API_ATTRIBUTE_TYPE.NESTED_LIST:
      return null
    default:
      return null
  }
}

export { AttributeFormField }
