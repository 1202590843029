import { useObjectGroupApi } from '@shared/api'
import { useMeta, useTitle } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AttributeGroupHeader, AttributeGroupTable } from '@widgets/settings'
import { useParams } from 'react-router-dom'

export const AttributeGroupPage: ReactFc = () => {
  const { id } = useParams()
  const attributeGroup = useObjectGroupApi(id)
  const title = useTitle(attributeGroup)

  useMeta({ title: title })

  return (
    <Layout header={<AttributeGroupHeader />}>
      <AttributeGroupTable />
    </Layout>
  )
}
