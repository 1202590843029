import { EuiIcon, EuiText, EuiTextTruncate } from '@elastic/eui'
import { useStyles } from '@shared/ui/styles'

import { type CategoryFilterItemProps } from '../../types'
import { getStyles } from './styles'

export const CategoryFilterItem: ReactFc<CategoryFilterItemProps> = ({ name, skus_count }) => {
  const styles = useStyles(getStyles)

  return (
    <EuiText css={styles.item}>
      <EuiIcon type="folderClosed" />
      <EuiTextTruncate
        text={`${name} (${skus_count})`}
        ellipsis={`… (${skus_count})`}
        css={styles.text}
      />
    </EuiText>
  )
}
